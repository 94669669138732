import React, { useEffect, useRef, useState } from "react";
import { Box, makeStyles, TextField, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import PhotoCameraRoundedIcon from "@material-ui/icons/PhotoCameraRounded";
import WallpaperRoundedIcon from "@material-ui/icons/WallpaperRounded";
import { dataURLToBlob, resizeImage } from "../../../../../Utils/misc";
import FlexboxVertical from "../../../../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../../../../SharedComponents/FlexboxHorizontal.jsx";
import WebCamDialog from "./WebCamDialog.jsx";
import MyBackdrop from "../../../../../SharedComponents/MyBackdrop.jsx";

const StyledButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.disabled,
    "& svg": {
      width: 40,
      height: 40,
    },
  },
}))(Button);

const useImagesStyles = makeStyles((theme) => ({
  root: {
    margin: "0 10px 0 0",
    position: "relative",
  },
  previewImage: {
    maxWidth: 90,
    height: 90,
    objectFit: "cover",
    borderRadius: 7,
  },
  textBlock: {
    marginRight: 20,
  },
  removeButton: {
    position: "absolute",
    right: 1,
    bottom: 5,
    minWidth: "unset",
    color: theme.palette.error.main,
    padding: 2,
    borderRadius: "50%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "white",
  },
  svgIcon: {
    width: 40,
    height: "auto",
    padding: 3,
    opacity: 0.38,
  },
  qrInput: {
    width: 200,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
  },
}));

/**
 * @param {String} src
 * @param {Function} removeButtonHandler
 * @param {Boolean} isAllowedToEdit
 */
const Image = ({ src, removeButtonHandler, isAllowedToEdit }) => {
  const classes = useImagesStyles();

  return (
    <Box className={classes.root} data-tag="image wrapper">
      <img className={classes.previewImage} src={src} alt="" />
      {isAllowedToEdit && (
        <>
          <div
            style={{ backgroundColor: "white", width: 12, height: 10, position: "absolute", right: 9, bottom: 14 }}
          />
          <Button className={classes.removeButton} onClick={removeButtonHandler}>
            <DeleteForeverIcon />
          </Button>
        </>
      )}
    </Box>
  );
};

Image.propTypes = {
  isAllowedToEdit: PropTypes.bool,
  removeButtonHandler: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};

/**
 * @param {Function} onImagesChange
 * @param {ExerciseType7Answer|ExerciseType7Legacy} answer
 * @param {Boolean} isAllowedToEdit
 * @param {function} onCodeInput
 * @returns {*}
 * @constructor
 */
const Exercise7Images = ({ onImagesChange, answer, isAllowedToEdit, onCodeInput }) => {
  const classes = useImagesStyles();
  const t = useTranslate();
  const pictureFileInput = useRef(/** @type {?HTMLInputElement} */ null);
  const [pictureFiles, setPictureFiles] = useState(/** @type {File[]} */ []);
  const [isBackdropOpen, setBackdropOpen] = useState(false);
  const [isWebCamOpened, openWebcam] = useState(false);
  const [approveCode, setApproveCode] = useState("");

  const [homeworkUserDataPhotos, setHomeworkUserDataPhotos] = useState(/** @type {RemoteMedia[]} */ []);

  //reset state
  useEffect(() => {
    setPictureFiles([]);
    setBackdropOpen(false);
    openWebcam(false);
    const photos = _.get(answer, "homework.userData.photos");
    setHomeworkUserDataPhotos((photos && [...photos]) || []);
  }, [answer]);

  // observe picture files
  useEffect(() => {
    onImagesChange && onImagesChange(pictureFiles, homeworkUserDataPhotos);
  }, [pictureFiles, onImagesChange, homeworkUserDataPhotos]);

  useEffect(() => {
    onCodeInput && onCodeInput(approveCode);
  }, [approveCode]);

  /** @param {ReactEventTarget} target */
  const onPictureSelected = async ({ target }) => {
    const totalImageCount = pictureFiles.length + homeworkUserDataPhotos.length;
    if (totalImageCount === 5) {
      return;
    }
    /** @type {File[]} */
    const files = [...target.files].filter((file) => file.type.split("/")[0] === "image");
    const newCount = 5 - totalImageCount;
    const length = Math.min(files.length, newCount);
    setBackdropOpen(true);
    for (let i = 0; i < length; i++) {
      files[i] = await resizeImage(files[i]);
      pictureFiles.push(files[i]);
    }
    setPictureFiles([...pictureFiles]);
    setBackdropOpen(false);
    pictureFileInput.current.value = "";
  };

  /** */
  const galleryClickHandler = () => {
    pictureFileInput.current && pictureFileInput.current.click();
  };

  /** @param {number} index */
  const removeImageFilesButtonHandler = (index) => () => {
    pictureFiles.splice(index, 1);
    setPictureFiles([...pictureFiles]);
  };

  /** @param {number} index */
  const removeHomeworkImageButtonHandler = (index) => () => {
    homeworkUserDataPhotos.splice(index, 1);
    setHomeworkUserDataPhotos([...homeworkUserDataPhotos]);
  };

  /** */
  const handleCamDialogClose = () => {
    openWebcam(false);
  };

  /** @param {String} image */
  const cameraDialogHandler = async (image) => {
    if (pictureFiles.length === 5) {
      return;
    }
    setPictureFiles([...pictureFiles, await resizeImage(new File([dataURLToBlob(image)], `photo-${Date.now()}.jpg`))]);
  };

  /** @param {ReactEventTarget} target */
  const ApproveCodeHandler = ({ target }) => {
    const value = target.value;
    setApproveCode(value);
  };

  return (
    <>
      <FlexboxVertical data-component="Images" alignSelf="stretch" margin="20px 20px 0">
        {isAllowedToEdit && (
          <FlexboxHorizontal data-tag="images file buttons" alignItems="center" justifyContent="center">
            <Typography className={classes.textBlock}>{t("Exercises.type7.addPhoto")}</Typography>
            <StyledButton onClick={() => openWebcam(true)}>
              <PhotoCameraRoundedIcon />
            </StyledButton>
            <StyledButton onClick={galleryClickHandler}>
              <WallpaperRoundedIcon />
            </StyledButton>
            {(answer.hasCodeToApprove || (answer.data && answer.data.hasCodeToApprove)) && (
              <TextField
                className={classes.qrInput}
                onChange={ApproveCodeHandler}
                value={approveCode}
                name="acceptance code"
                label={t("Exercises.type7.codeToApprove")}
                variant="outlined"
                fullWidth={false}
              />
            )}
          </FlexboxHorizontal>
        )}
        <FlexboxHorizontal
          data-tag="image previews"
          marginTop="10px"
          marginBottom={homeworkUserDataPhotos?.length > 0 || pictureFiles?.length > 0 ? "10px" : undefined}
        >
          {homeworkUserDataPhotos.map((remoteMedia, i) => (
            <Image
              key={remoteMedia.name + i}
              src={remoteMedia.url}
              removeButtonHandler={removeHomeworkImageButtonHandler(i)}
              isAllowedToEdit={isAllowedToEdit}
            />
          ))}
          {pictureFiles.map((file, i) => (
            <Image
              key={file.name + i}
              src={URL.createObjectURL(file)}
              removeButtonHandler={removeImageFilesButtonHandler(i)}
              isAllowedToEdit
            />
          ))}
        </FlexboxHorizontal>
      </FlexboxVertical>
      <WebCamDialog open={isWebCamOpened} onClose={handleCamDialogClose} onCaptured={cameraDialogHandler} />
      <MyBackdrop open={isBackdropOpen} />
      <input
        style={{ display: "none" }}
        type="file"
        ref={pictureFileInput}
        accept="image/png, image/jpeg"
        onChange={onPictureSelected}
        multiple
      />
    </>
  );
};

Exercise7Images.propTypes = {
  answer: PropTypes.object.isRequired,
  isAllowedToEdit: PropTypes.bool,
  onImagesChange: PropTypes.func,
  onCodeInput: PropTypes.func,
};

export default Exercise7Images;

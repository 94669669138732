import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import clsx from "clsx";
import grey from "@material-ui/core/colors/grey.js";
import Button from "@material-ui/core/Button";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { makeStyles, withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useLocale } from "../Providers/i18n/I18nProvider.jsx";
import rusLangIcon from "../assets/ic_rus_lang.svg";
import { languageCountryMap } from "../constants.js";

const WhiteTextButton = withStyles({
  root: {
    color: "white",
  },
})(Button);

const StyledMenu = withStyles({
  paper: {
    minWidth: 50,
  },
  list: {
    padding: 0,
  },
})(Menu);

const StyledMenuItem = withStyles({
  root: {
    padding: 1,
    minHeight: 50,
    justifyContent: "center",
  },
})(MenuItem);

const useStyles = makeStyles((theme) => ({
  flagRoot: {
    objectFit: "cover",
    borderRadius: "50%",
    margin: 5,
  },
  active: {
    boxShadow: `0px 0px 0px 4px ${grey[50]}, 0px 0px 0px 6px ${theme.palette.primary.dark}`,
  },
}));

/**
 * @param {String} lang
 * @param {number} size
 * @param {string} className
 */
const LanguageIcon = ({ lang, size, className }) => {
  const classes = useStyles();
  const flagInlineStyles = { width: size, height: size }; // because ReactCountryFlag overrides styles

  if (lang === "ru") {
    return <img src={rusLangIcon} className={clsx(classes.flagRoot, className)} style={flagInlineStyles} alt="" />;
  }

  return (
    <ReactCountryFlag
      className={clsx(classes.flagRoot, className)}
      countryCode={languageCountryMap[lang]}
      svg
      style={flagInlineStyles}
    />
  );
};

LanguageIcon.propTypes = {
  lang: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

/**
 * @param {Object} buttonProps
 * @param {Object} menuProps
 */
const LanguageSelect = ({ buttonProps, menuProps }) => {
  const { locale, setLocale, localeCodes } = useLocale();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  /** @param {Event} event */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /** */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /** @param {String} lang */
  const onLangSelected = (lang) => {
    setLocale(lang);
    handleClose();
  };

  return (
    <>
      <WhiteTextButton {...buttonProps} size="small" onClick={handleClick}>
        <LanguageIcon lang={locale} size={24} />
        <ExpandMoreRoundedIcon />
      </WhiteTextButton>
      <StyledMenu
        id="lang-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        {...menuProps}
      >
        {localeCodes.map((_locale) => (
          <StyledMenuItem key={_locale} onClick={() => onLangSelected(_locale)}>
            <LanguageIcon className={clsx({ [classes.active]: locale === _locale })} lang={_locale} size={22} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

LanguageSelect.propTypes = {
  buttonProps: PropTypes.object,
  menuProps: PropTypes.object,
};

export default LanguageSelect;

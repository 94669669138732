import React from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import { Box, makeStyles, Typography, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { TextField } from "mui-rff";
import ReactFinalForm from "../ReactFinalForm.jsx";
import { useCourseProvider } from "../../Providers/Data/CourseProvider.jsx";
import useDateUtils from "../../hooks/useDateUtils.js";
import FeedbackDialogActions from "./FeedbackDialogActions.jsx";
import FeedbackDialogSlider from "./FeedbackDialogSlider.jsx";

const useStyles = makeStyles({
  instructionText: {
    textAlign: "center",
    fontWeight: 500,
  },
  defaultInstruction: {
    fontSize: 12,
    textAlign: "center",
  },
  content: {
    padding: 20,
  },
  dateBox: {
    fontSize: 12,
  },
});

const useDialogStyles = makeStyles({
  root: {
    "& .MuiDialogTitle-root": {
      width: "100%",
      backgroundColor: "#F8F9FE",
      marginTop: 20,
      marginBottom: 25,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F8F9FE",
      borderRadius: 12,
    },
  },
});

/** @param {FeedbackDialog} props */
const FeedbackDialog = ({ title, instructionText, id, resource, date, onClose, open }) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const { postUserReviews } = useCourseProvider();
  const t = useTranslate();
  const { format } = useDateUtils();

  /** @param {FeedbackDataForm} data
   * @param form
   */
  const onSubmit = async ({ rating, text }, form) => {
    const eventId = resource === "event" ? id : null;
    const deckId = resource === "deck" ? id : null;
    const postData = { eventId, deckId, reviewOf: resource, text, rating };

    await postUserReviews(postData);
    form.reset();
    onClose();
  };

  /** */
  const onCloseDialog = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseDialog} maxWidth="sm" fullWidth classes={dialogClasses} disableEscapeKeyDown>
      <ReactFinalForm
        onSubmit={onSubmit}
        initialValues={{
          rating: 0,
          text: "",
        }}
      >
        <Box width="80%" mr="auto" ml="auto">
          <DialogTitle>
            {date && (
              <Typography className={classes.dateBox} color="inherit">
                {format(date, "dd/MM/yyyy")}
              </Typography>
            )}
            {title}
          </DialogTitle>
          <DialogContent className={classes.content}>
            {instructionText && (
              <Typography className={classes.instructionText} color="inherit">
                {instructionText}
              </Typography>
            )}
            <Typography className={classes.defaultInstruction} color="inherit">
              {t("feedbackDialog.defaultInstruction")}
            </Typography>
            <FeedbackDialogSlider />
            <TextField
              placeholder={t("feedbackDialog.placeholder")}
              name="text"
              variant="outlined"
              multiline={true}
              rows={4}
              rowsMax={6}
              fullWidth
            />
          </DialogContent>
          <FeedbackDialogActions id={id} resource={resource} onClose={onClose} />
        </Box>
      </ReactFinalForm>
    </Dialog>
  );
};

FeedbackDialog.propTypes = {
  title: PropTypes.string.isRequired,
  instructionText: PropTypes.string,
  id: PropTypes.number.isRequired,
  resource: PropTypes.oneOf(["event", "deck"]).isRequired,
  date: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FeedbackDialog;

import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import DescriptionIcon from "@material-ui/icons/Description";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { useTranslate } from "react-polyglot";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FlexboxVertical from "../../../../../SharedComponents/FlexboxVertical.jsx";
import FlexboxHorizontal from "../../../../../SharedComponents/FlexboxHorizontal.jsx";
import DownloadLink from "../../../../../SharedComponents/DownloadLink.jsx";

const FILES_MAX_COUNT = 5;

const useAddFilesButtonStyles = makeStyles(() => ({
  buttonRoot: {
    "& .MuiSvgIcon-root": {
      marginRight: 10,
    },
  },
}));

const useFileItemStyles = makeStyles((theme) => ({
  removeButton: {
    color: theme.palette.error.main,
  },
}));

/**
 * @param {function} onClick
 * @param {string} label
 */
const AddFilesButton = ({ onClick, label }) => {
  const classes = useAddFilesButtonStyles();

  return (
    <Button className={classes.buttonRoot} onClick={onClick} color="secondary">
      <DescriptionIcon />
      <span>{label}</span>
    </Button>
  );
};

AddFilesButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

/**
 * @param {File|RemoteMedia} file
 * @param {Function} removeButtonHandler
 * @param {Boolean} isAllowedToEdit
 */
const FileItem = ({ file, removeButtonHandler, isAllowedToEdit }) => {
  const classes = useFileItemStyles();

  return (
    <Box display="inline-flex" margin="0 30px 0 0">
      {isAllowedToEdit && (
        <IconButton className={classes.removeButton} size="small" onClick={removeButtonHandler}>
          <DeleteForeverIcon fontSize="inherit" />
        </IconButton>
      )}
      {!file.url && <Typography>{file.name}</Typography>}
      {file.url && <DownloadLink href={file.url} fileName={file.name} linkText={file.name} />}
    </Box>
  );
};

FileItem.propTypes = {
  file: PropTypes.object.isRequired,
  removeButtonHandler: PropTypes.func.isRequired,
  isAllowedToEdit: PropTypes.bool,
};

/**
 * @param {function} onFilesChanged
 * @param {ExerciseType7Answer|ExerciseType7Legacy} answer
 * @param {Boolean} isAllowedToEdit
 */
const Exercise7Files = ({ onFilesChanged, answer, isAllowedToEdit }) => {
  const t = useTranslate();
  const anyFileInput = useRef(/** @type {?HTMLInputElement} */ null);
  const [selectedFiles, setSelectedFiles] = useState(/** @type {File[]} */ []);
  const [homeworkUserDataFiles, setHomeworkUserDataFiles] = useState(/** @type {RemoteMedia[]} */ []);

  //reset state
  useEffect(() => {
    setSelectedFiles([]);
    const _files = _.get(answer, "homework.userData.files");
    setHomeworkUserDataFiles((_files && [..._files]) || []);
  }, [answer]);

  // observe files
  useEffect(() => {
    onFilesChanged && onFilesChanged(selectedFiles, homeworkUserDataFiles);
  }, [selectedFiles, onFilesChanged, homeworkUserDataFiles]);

  /** */
  const filesButtonClick = () => {
    anyFileInput.current && anyFileInput.current.click();
  };

  /** @param {number} index */
  const removeFilesButtonHandler = (index) => () => {
    selectedFiles.splice(index, 1);
    setSelectedFiles([...selectedFiles]);
  };

  /** @param {number} index */
  const removeHomeworkFileButtonHandler = (index) => () => {
    homeworkUserDataFiles.splice(index, 1);
    setHomeworkUserDataFiles([...homeworkUserDataFiles]);
  };

  /** @param {ReactEventTarget} target */
  const onAnyFilesSelected = async ({ target }) => {
    const totalFilesCount = selectedFiles.length + homeworkUserDataFiles.length;
    if (totalFilesCount === FILES_MAX_COUNT) {
      return;
    }
    /** @type {File[]} */
    const files = target.files;
    const newCount = FILES_MAX_COUNT - totalFilesCount;
    const length = Math.min(files.length, newCount);
    for (let i = 0; i < length; i++) {
      selectedFiles.push(files[i]);
    }
    setSelectedFiles([...selectedFiles]);
    anyFileInput.current.value = "";
  };

  return (
    <FlexboxVertical data-component="Files" alignSelf="stretch" alignItems="center">
      {isAllowedToEdit && <AddFilesButton label={t("Exercises.type7.addFiles")} onClick={filesButtonClick} />}
      <FlexboxHorizontal alignSelf="stretch" flexWrap="wrap">
        {homeworkUserDataFiles.map((file, i) => (
          <FileItem
            key={file.path}
            file={file}
            removeButtonHandler={removeHomeworkFileButtonHandler(i)}
            isAllowedToEdit={isAllowedToEdit}
          />
        ))}
        {selectedFiles.map((file, i) => (
          <FileItem
            key={file.name + file.size + file.lastModified}
            file={file}
            removeButtonHandler={removeFilesButtonHandler(i)}
            isAllowedToEdit
          />
        ))}
      </FlexboxHorizontal>
      <input style={{ display: "none" }} type="file" ref={anyFileInput} onChange={onAnyFilesSelected} multiple />
    </FlexboxVertical>
  );
};

Exercise7Files.propTypes = {
  onFilesChanged: PropTypes.func,
  answer: PropTypes.object.isRequired,
  isAllowedToEdit: PropTypes.bool,
};

export default Exercise7Files;

import React, { useEffect, useState } from "react";
import { Card, CardContent, CardMedia, makeStyles, Typography } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import PrimaryDarkTypography from "../../../SharedComponents/PrimaryDarkTypography.jsx";
import { useNews } from "../../../Providers/Data/NewsProvider.jsx";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    minHeight: 350,
    [theme.breakpoints.up(520)]: {
      flexDirection: "row",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
    padding: "18px 24px",
  },
  media: {
    minWidth: "100%",
    minHeight: 350,
    [theme.breakpoints.up(520)]: {
      minWidth: "33.333%",
    },
  },
  action: {
    padding: "18px 24px",
  },
}));

/** */
const NewsContent = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { news, watchNews } = useNews();
  const newsId = useExtractRouteParamInt("/news/:newsId", "newsId");
  const [theNews, setNews] = useState(/** @type {?TheNews} */ null);

  // init content
  useEffect(() => {
    setNews(news.find((n) => n.id === newsId));
    watchNews(newsId);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsId, news]);

  if (!theNews) {
    return null;
  }

  /** */
  const cardClickHandler = () => {
    window.open(theNews.url, "_blank").focus();
  };

  return (
    <Card className={classes.cardRoot}>
      <CardMedia className={classes.media} image={theNews.image.url} />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <PrimaryDarkTypography variant="h6" gutterBottom>
            {theNews.name}
          </PrimaryDarkTypography>
          <Typography variant="body2">{theNews.content}</Typography>
        </CardContent>
        {theNews.url && (
          <CardActions className={classes.action}>
            <Button onClick={cardClickHandler} variant="contained" color="primary">
              {t("details")}
            </Button>
          </CardActions>
        )}
      </div>
    </Card>
  );
};

export default NewsContent;

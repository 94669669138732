import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslate } from "react-polyglot";
import Typography from "@material-ui/core/Typography";
import Exercise7 from "../Learning/Exercises/Exercise7.jsx";
import { useHomeworks } from "../../../Providers/Data/HomeworksProvider.jsx";
import FlexboxVertical from "../../../SharedComponents/FlexboxVertical.jsx";
import PrimaryRoundedButton from "../../../SharedComponents/PrimaryRoundedButton.jsx";

const useStyles = makeStyles((theme) => ({
  title: {
    margin: "35px 10px 0px",
    padding: "0 20px",
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
  postButton: {
    minWidth: 300,
    marginTop: 30,
  },
}));

/** */
const ToDoHomework = () => {
  const classes = useStyles();
  const t = useTranslate();
  const { currentToDoHomework, saveTodoHomework } = useHomeworks();
  const checkAnswerRef = useRef();
  const [isCheckButtonDisabled, setCheckButtonDisabled] = useState(true);

  if (!currentToDoHomework) return null;

  /** */
  const postButtonHandler = async () => {
    const answerResult = checkAnswerRef.current && (await checkAnswerRef.current());
    if (answerResult) {
      saveTodoHomework(answerResult.payload).then();
    }
  };

  return (
    <FlexboxVertical alignItems="center" marginBottom="20px">
      <Typography className={classes.title}>{t("homeworks.todoHomework")}</Typography>
      <Exercise7
        checkAnswerRef={checkAnswerRef}
        exercise={currentToDoHomework}
        setCheckButtonDisabled={setCheckButtonDisabled}
      />
      <PrimaryRoundedButton className={classes.postButton} onClick={postButtonHandler} disabled={isCheckButtonDisabled}>
        {t("homeworks.postToDoButton")}
      </PrimaryRoundedButton>
    </FlexboxVertical>
  );
};

export default ToDoHomework;

import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslate } from "react-polyglot";
import { TextField, Checkboxes } from "mui-rff";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import { useFormState, Field } from "react-final-form";
import PropTypes from "prop-types";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";
import { useBaseData } from "../../../Providers/Data/BaseDataProvider.jsx";
import ReactFinalForm from "../../../SharedComponents/ReactFinalForm.jsx";
import { useHttp } from "../../../Providers/Common/HttpProvider.jsx";
import PasswordFormField from "../../../SharedComponents/PasswordFormField.jsx";
import { ReactComponent as User_visible } from "../../../assets/ic_user_visible.svg";
import { ReactComponent as User_hidden } from "../../../assets/ic_user_hidden.svg";
import { useDialogs } from "../../../Providers/Common/DialogsProvider.jsx";
import ic_empty_avatar from "../../../assets/ic_empty_avatar.svg";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    "& .MuiTextField-root:not(:first-child)": {
      marginTop: 15,
    },
  },
  dialogActions: {
    padding: 24,
    justifyContent: "space-between",
  },
  icon: {
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    flexShrink: 0,
  },
  checkBox: {
    marginRight: 8,
    marginLeft: 0,
  },
  imgBox: {
    position: "relative",
  },
  userAvatar: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
  },
  avatarContainer: {
    position: "relative",
    width: 100,
    height: 100,
  },
  avatarInput: {
    width: 100,
    height: 100,
    color: "transparent",
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "visible",

    "&::file-selector-button": {
      border: "none",
      width: 100,
      height: 100,
      cursor: "pointer",
      backgroundColor: "transparent",
      color: "transparent",
      overflow: "visible",
    },
  },
  removeButton: {
    position: "absolute",
    right: "-6px",
    bottom: "-6px",
    minWidth: "unset",
    color: theme.palette.error.main,
    padding: 2,
    borderRadius: "50%",
  },
}));

/** */
const PublicInfoCheckBox = ({ fieldName }) => {
  const classes = useStyles();

  return (
    <Checkboxes
      data={{
        label: "",
        value: true,
      }}
      icon={<User_hidden className={classes.icon} />}
      checkedIcon={<User_visible className={classes.icon} />}
      name={fieldName}
      formControlLabelProps={{ className: classes.checkBox }}
    />
  );
};

PublicInfoCheckBox.propTypes = {
  fieldName: PropTypes.string,
};

/**
 * @param {Object} input
 * @return {*}
 * @constructor
 */
const ImageUploader = ({ input }) => {
  const classes = useStyles();
  const { userInfo } = useBaseData();
  const [pictureFiles, setPictureFiles] = useState(/** @type {RemoteMedia} */ null);

  useEffect(() => {
    if (userInfo.photo) {
      setPictureFiles(userInfo.photo);
    }
  }, [userInfo]);

  /**
   * @param {Event} e
   */
  const photoHandler = (e) => {
    const file = e.target.files[0];
    input.onChange(file);
    setPictureFiles({ url: URL.createObjectURL(file) });
  };

  /** */
  const handleReset = () => {
    setPictureFiles({ url: "" });
    input.onChange(null);
  };

  return (
    <div className={classes.avatarContainer}>
      <div className={classes.imgBox}>
        <img
          className={classes.userAvatar}
          src={pictureFiles && pictureFiles.url ? pictureFiles.url : ic_empty_avatar}
          alt="User avatar"
        />
      </div>
      <input
        className={classes.avatarInput}
        type="file"
        accept="image/*,.png,.jpg,.jpeg,.gif,.web"
        onChange={photoHandler}
      />
      <Button type="button" className={classes.removeButton} onClick={handleReset}>
        <RemoveCircleRoundedIcon fontSize="large" />
      </Button>
    </div>
  );
};

ImageUploader.propTypes = {
  input: PropTypes.object,
};

/* eslint-disable react/prop-types */
/** */
const EditUserInfoDialog = ({ onClose, ...rest }) => {
  const classes = useStyles();
  const t = useTranslate();
  const { post } = useHttp();
  const snackbar = useDialogs();
  const { updateUserInfo, userInfo } = useBaseData();

  /** @param {UserDataSubmitForm} data */
  const onSubmit = async (data) => {
    const clone = { ...data };
    delete clone.newPasswordConfirm;

    const file = clone.photo;
    if (file) {
      const formData = new FormData();
      formData.append("file", file, file.name);

      /** @type {PostFileResponse} */
      const response = await post("upload/users", formData, (error) => {
        snackbar.error(error);
      });
      if (response) {
        clone.photo = {
          name: response.originalname,
          path: response.path,
        };
      } else {
        return;
      }
    } else {
      clone.photo = {
        name: "",
        path: "",
      };
    }

    if (userInfo.email.toUpperCase() === data.email.toUpperCase()) {
      delete clone.email;
    }

    if (data.phone === undefined) {
      clone.phone = "";
    }

    if (data.personalLink === undefined) {
      clone.personalLink = "";
    }

    if (data.aboutMe === undefined) {
      clone.aboutMe = "";
    }

    if (await updateUserInfo(clone)) {
      onClose();
    }
  };

  return (
    <Dialog {...rest} onClose={onClose} maxWidth="xs" fullWidth>
      <ReactFinalForm
        onSubmit={onSubmit}
        initialValues={{
          name: userInfo.name,
          email: userInfo.email,
          personalLink: userInfo.personalLink,
          phone: userInfo.phone,
          aboutMe: userInfo.aboutMe,
          fieldsToBePublic: {
            email: userInfo.fieldsToBePublic.email,
            phone: userInfo.fieldsToBePublic.phone,
            photo: userInfo.fieldsToBePublic.photo,
            aboutMe: userInfo.fieldsToBePublic.aboutMe,
            personalLink: userInfo.fieldsToBePublic.personalLink,
          },
        }}
        validate={({ newPassword, newPasswordConfirm }) => {
          const errors = {};
          if (newPassword !== newPasswordConfirm) {
            errors.newPasswordConfirm = t("passwordsMatch");
          }
          return errors;
        }}
      >
        <DialogTitle>{t("profileEdit")}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Field name="photo" component={ImageUploader} />
          <TextField label={t("name")} name="name" />
          <TextField
            autoComplete="off"
            label={t("email")}
            name="email"
            InputProps={{
              endAdornment: <PublicInfoCheckBox fieldName="fieldsToBePublic.email" />,
            }}
          />
          <TextField
            autoComplete="off"
            label={t("personalLink")}
            name="personalLink"
            InputProps={{
              endAdornment: <PublicInfoCheckBox fieldName="fieldsToBePublic.personalLink" />,
            }}
          />
          <TextField
            label={t("phone")}
            name="phone"
            InputProps={{
              endAdornment: <PublicInfoCheckBox fieldName="fieldsToBePublic.phone" />,
            }}
          />
          <TextField
            label={t("aboutMe")}
            name="aboutMe"
            multiline
            rowsMax={4}
            InputProps={{
              endAdornment: <PublicInfoCheckBox fieldName="fieldsToBePublic.aboutMe" />,
            }}
          />
          <PasswordFormField labelTranslationKey="currentPassword" name="currentPassword" />
          <PasswordFormField labelTranslationKey="newPassword" name="newPassword" />
          <PasswordFormField labelTranslationKey="confirmNewPassword" name="newPasswordConfirm" />
        </DialogContent>
        <DialogActions className={classes.dialogActions} onClose={onClose} />
      </ReactFinalForm>
    </Dialog>
  );
};

/** */
const DialogActions = ({ onClose, ...rest }) => {
  const t = useTranslate();
  const { isLoading } = useHttp();
  const formState = useFormState();

  return (
    <MuiDialogActions {...rest}>
      <Button onClick={onClose}>{t("cancel")}</Button>
      <Button type="submit" color="secondary" variant="contained" disabled={formState.invalid || formState.pristine}>
        {!isLoading ? "OK" : <CircularProgress color="inherit" size={24} />}
      </Button>
    </MuiDialogActions>
  );
};

export default EditUserInfoDialog;

import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Link, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslate } from "react-polyglot";
import DOMPurify from "dompurify";
import ExerciseTitle from "./ExerciseTitle.jsx";
import LearnAudioPlayer from "./LearnAudioPlayer.jsx";
import ImageQuestion from "./ImageQuestion.jsx";
import UniversalVideoPlayer from "../../../../../SharedComponents/UniversalVideoPlayer.jsx";
import { replaceUrlsWithLinks } from "../../../../../Utils/misc.js";

const useStyles = makeStyles((theme) => ({
  textQuestion: {
    fontSize: 19,
    fontWeight: 500,
    margin: "20px 30px 20px",
    whiteSpace: "pre-line",
  },
  videoPlayerWrapper: {
    width: 400,
    marginTop: 10,
    "&>div": {
      width: "100%",
    },
  },
  hr: {
    width: "100%",
    height: 2,
    minHeight: 2,
    marginTop: 40,
    backgroundColor: theme.palette.primary.main,
    border: "none",
  },
  audioWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: 48,
    width: "100%",
    padding: "20px 0",
  },
}));

/**
 * @param {string} data
 * @return {{__html: *}}
 */
const sanitizedData = (data) => ({
  __html: DOMPurify.sanitize(data),
});

/**
 * @param {Object} classes
 * @param {MediaQuestion} exerciseQuestion
 * @param {String} exerciseTitle
 * @param {String} defaultTitle
 * @param {Boolean} disableBottomSeparator
 * @returns {*}
 * @constructor
 */
const Question = ({ exerciseQuestion, exerciseTitle, defaultTitle, disableBottomSeparator }) => {
  const classes = useStyles();
  const t = useTranslate();
  const question = exerciseQuestion;
  let partOfQuestion = question.text && question.text.split("///");
  const [isOpen, setOpen] = useState(false);

  if (partOfQuestion) {
    partOfQuestion = partOfQuestion.map((text) => replaceUrlsWithLinks(text));
  }

  return (
    <>
      {!exerciseTitle && <ExerciseTitle>{defaultTitle}</ExerciseTitle>}
      {exerciseTitle && <ExerciseTitle>{exerciseTitle}</ExerciseTitle>}
      {question.text && (
        <Typography className={classes.textQuestion}>
          <span dangerouslySetInnerHTML={sanitizedData(partOfQuestion[0])} />
          {isOpen && partOfQuestion[1] && <span dangerouslySetInnerHTML={sanitizedData(partOfQuestion[1])} />}
        </Typography>
      )}
      {partOfQuestion[1] && (
        <Link
          component="button"
          variant="caption"
          underline="always"
          color="textSecondary"
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          {isOpen ? t("hide") : t("showMore")}
        </Link>
      )}
      {question.audio && (
        <Box data-tag="audio question" className={classes.audioWrapper}>
          <LearnAudioPlayer url={question.audio.url} />
        </Box>
      )}
      {question.image && <ImageQuestion image={question.image} />}
      {question.video && (
        <div className={classes.videoPlayerWrapper}>
          <UniversalVideoPlayer url={question.video} height={225} />
        </div>
      )}
      {!disableBottomSeparator && <hr className={classes.hr} />}
    </>
  );
};

Question.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
  disableBottomSeparator: PropTypes.bool,
  exerciseQuestion: PropTypes.object.isRequired,
  exerciseTitle: PropTypes.string.isRequired,
};

export default Question;

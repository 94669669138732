import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCourseProvider } from "./CourseProvider.jsx";
import { useHttp } from "../Common/HttpProvider.jsx";
import useEffectAsync from "../../hooks/useEffectAsync.js";
import useEffectWithPreviousValues from "../../hooks/useEffectWithPreviousValues.js";
import useExtractRouteParamInt from "../../hooks/useExtractRouteParamInt.js";

export const DEFAULT_LEARNED_PATCH = Object.freeze({
  learned: true,
  skip: false,
  attempt: 1,
  payload: {},
});

/**
 * @callback RequestOtherExercise
 * @param {Number} direction - if direction > -1 then function request next exercise, previous exercise otherwise
 * @returns {Promise<void>}
 */

/**
 * @callback RequestOtherWord
 * @param {Number} direction - if direction > -1 then function request next word, previous word otherwise
 * @returns {Promise<void>}
 */

/**
 * @typedef {Object} LearnProcessProviderContext
 * @property {?WordLearningResponse} word
 * @property {?Number} specifiedWordId
 * @property {?VideosResponse} videos
 * @property {?ExerciseResponse} exercise
 * @property {?Number} specifiedExerciseId
 * @property {?VideoLearningResponse} learnVideoResult
 * @property {Boolean} isExpertMode
 * @property {Number} selectedVideoIndex
 * @property {Boolean} areWordsExpanded
 * @property {Number} videoWordPatching
 * @property {Number[]} learnedVideos
 * @property {FnBooleanVoid} learnWord
 * @property {FnAsyncNumberBooleanVoid} learnVideoWord
 * @property {FnAsyncNumberVoid} learnVideo
 * @property {FnBooleanVoid} setExpertMode
 * @property {Function} setSelectedVideoIndex
 * @property {Function} setWordsExpanded
 * @property {FnPatchExercise} patchExercise
 * @property {Function} skipExercise
 * @property {FnAsyncStringOptVoid} userIsWrong
 * @property {RequestOtherExercise} requestOtherExercise
 * @property {RequestOtherWord} requestOtherWord
 * @property {FnAsyncStringVoid} saveUsersAnswer
 * @property {Rewards} rewards
 */

/**
 * @type {React.Context<LearnProcessProviderContext>}
 */
const learnContext = createContext({
  word: null,
  videos: null,
  exercise: null,
  specifiedWordId: null,
  specifiedExerciseId: null,
  learnVideoResult: null,
  isExpertMode: false,
  selectedVideoIndex: 0,
  areWordsExpanded: false,
  videoWordPatching: -1,
  learnedVideos: [],
  rewards: null,
  /** @type FnBooleanVoid */ learnWord: () => {},
  /** @type {FnAsyncNumberBooleanVoid} */ learnVideoWord: () => {},
  /** @type {FnAsyncNumberVoid} */ learnVideo: () => {},
  /** @type FnBooleanVoid */ setExpertMode: () => {},
  setSelectedVideoIndex: () => {},
  setWordsExpanded: () => {},
  /** @type FnPatchExercise */ patchExercise: () => {},
  skipExercise: () => {},
  /** @type FnAsyncStringOptVoid */ userIsWrong: () => {},
  /** @type {RequestOtherExercise} */ requestOtherExercise: () => {},
  /** @type {RequestOtherWord} */ requestOtherWord: () => {},
  /** @type {FnAsyncStringVoid} */ saveUsersAnswer: () => {},
});

/**
 * @returns {LearnProcessProviderContext}
 */
export const useLearnProcess = () => useContext(learnContext);

/**
 * @param {React.ReactNode} children
 * @returns {*}
 * @constructor
 */
const LearnProcessProvider = ({ children }) => {
  const { get, patch, post } = useHttp();
  const history = useHistory();
  const { currentDeck, currentBranch, updateCourseData } = useCourseProvider();
  const [isExpertMode, setExpertMode] = useState(false);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [areWordsExpanded, setWordsExpanded] = useState(false);
  const [videoWordPatching, setVideoWordPatching] = useState(-1);
  const [learnedVideos, setLearnedVideos] = useState(/** @type {Number[]} */ []);
  const wordId = useExtractRouteParamInt("/learn/:deckId/:wordId", "wordId");
  const exerciseId = useExtractRouteParamInt("/learn/:deckId/exercise/:exerciseId", "exerciseId");
  // Learning
  const [word, setWord] = useState(/** @type ?WordLearningResponse */ null);
  const [videos, setVideos] = useState(/** @type ?VideosResponse */ null);
  const [learnVideoResult, setLearnVideoResult] = useState(/** @type ?VideoLearningResponse */ null);
  const [exercise, setExercise] = useState(/** @type {?ExerciseResponse} */ null);
  const [rewards, setRewards] = useState(/** @type {?Rewards} */ null);

  /** @param {Number} deckId */
  const requestRandomWord = async (deckId) => {
    const resp = await get(`words/random/${deckId}`);
    if (resp) {
      setWord(resp);
    }
  };

  /**
   * @param {Number} wordId
   * @param {Number} deckId
   */
  const requestWordById = async (wordId, deckId) => {
    const resp = await get(`words/${wordId}/${deckId}`);
    if (resp) {
      setWord(resp);
    }
  };
  /** @type {RequestOtherWord} */
  const requestOtherWord = async (direction) => {
    let lastSegment = "previous";
    if (direction > -1) {
      lastSegment = "next";
    }
    const resp = await get(`words/${word.word.id}/${currentDeck.id}/${lastSegment}`);
    if (resp) {
      setWord(resp);
    }
  };

  /** @type FnAsyncNumberNumberBooleanBoolean */
  const postWordLearningResult = async (wordId, deckId, learned) => {
    const resp = await patch(`words/${wordId}/${deckId}`, { learned });
    if (resp) {
      setWord(resp);
      setRewards(resp.rewards);
      return true;
    } else {
      return false;
    }
  };

  // update deck id in path when branch is changing
  useEffectWithPreviousValues(
    /** @param {Branch} prevBranch */
    ([prevBranch]) => {
      const decks = currentBranch && currentBranch.decks;
      if (!wordId && prevBranch && currentBranch && prevBranch.id !== currentBranch.id && decks && decks.length > 0) {
        history.push(`/learn/${decks[0].id}`);
      }
    },
    [currentBranch],
  );

  // request first word
  useEffectWithPreviousValues(
    /** @param {Deck} prevDeck */
    ([prevDeck]) => {
      if (!currentDeck || !currentDeck.open || currentDeck.words.count === 0) {
        return;
      }
      if (wordId) {
        requestWordById(wordId, currentDeck.id).then();
      } else if (!word || prevDeck.id !== currentDeck.id) {
        requestRandomWord(currentDeck.id).then();
      } else {
        requestWordById(word.word.id, currentDeck.id).then();
      }
    },
    [currentDeck, wordId],
  );

  //request videos
  useEffectAsync(async () => {
    if (!currentDeck || !currentDeck.open || currentDeck.videos.count === 0) {
      return;
    }
    /** @type {VideosResponse} */
    const resp = await get(`videos/list/${currentDeck.id}`);
    if (resp) {
      setVideos(resp);
    }
  }, [currentDeck, word, learnVideoResult]);

  //request random exercise
  useEffectWithPreviousValues(
    /** @param {?Deck} prevDeck */
    ([prevDeck]) => {
      const asyncFun = async () => {
        if (!currentDeck || !currentDeck.open || currentDeck.exercises.count === 0 || exerciseId) {
          return;
        }
        if (prevDeck && prevDeck.id === currentDeck.id) {
          return;
        }
        /** @type {ExerciseResponse} */
        const resp = await get(`exercises/random/${currentDeck.id}`);
        if (resp) {
          setExercise(resp);
        }
      };
      asyncFun().then();
    },
    [currentDeck, exerciseId],
  );

  //request exercise by id specified in path
  useEffectAsync(async () => {
    if (!currentDeck || !currentDeck.open || currentDeck.exercises.count === 0) {
      return;
    }
    if (exerciseId) {
      /** @type {ExerciseResponse} */
      const resp = await get(`exercises/${exerciseId}/${currentDeck.id}`);
      if (resp) {
        setExercise(resp);
      }
    }
  }, [currentDeck, exerciseId]);

  /** @type {RequestOtherExercise} */
  const requestOtherExercise = async (direction) => {
    let lastSegment = "previous";
    if (direction > -1) {
      lastSegment = "next";
    }
    const resp = await get(`exercises/${exercise.exercise.id}/${currentDeck.id}/${lastSegment}`);
    if (resp) {
      setExercise(resp);
    }
  };

  /** @type FnBooleanVoid */
  const learnWord = async (learned) => {
    const resp = await postWordLearningResult(word.word.id, currentDeck.id, learned);
    if (resp) {
      if (wordId) {
        history.push(`/learn/${currentDeck.id}`);
      }
      updateCourseData();
    }
  };

  /** @type {FnAsyncNumberBooleanVoid} */
  const learnVideoWord = async (wordId, learned) => {
    setVideoWordPatching(wordId);
    const result = await postWordLearningResult(wordId, currentDeck.id, learned);
    if (result) {
      const currentWordIndex = videos.videos[selectedVideoIndex].words.findIndex((word) => word.id === wordId);
      videos.videos[selectedVideoIndex].words[currentWordIndex].learned = true;
      setVideos({ ...videos });
      updateCourseData();
    }
    setVideoWordPatching(-1);
  };

  /** @type {FnAsyncNumberVoid} */
  const learnVideo = async (videoId) => {
    if (learnedVideos.findIndex((id) => id === videoId) < 0) {
      setLearnedVideos([...learnedVideos, videoId]);
    } else {
      return;
    }
    /** @type {VideoLearningResponse} */
    const resp = await patch(`videos/${videoId}/${currentDeck.id}`, { learned: true });
    if (resp) {
      setLearnVideoResult(resp);
      updateCourseData();
      setRewards(resp.rewards);
    } else {
      const videoIndex = learnedVideos.findIndex((id) => id === videoId);
      if (videoIndex >= 0) {
        learnedVideos.splice(videoIndex, 1);
        setLearnedVideos([...learnedVideos]);
      }
    }
  };

  /** @type {FnPatchExercise} */
  const patchExercise = async (body) => {
    const resp = await patch(`exercises/${exercise.exercise.id}/${currentDeck.id}`, body);
    if (resp) {
      setExercise(resp);
      updateCourseData();
      setRewards(resp.rewards);
    }
  };

  /** */
  const skipExercise = () => {
    patchExercise({
      learned: false,
      skip: true,
      attempt: 1,
      payload: {},
    }).then();
  };

  /**
   * @param {string} [answerValue]
   * @returns {Promise<void>}
   */
  const userIsWrong = async (answerValue) => {
    await patchExercise({
      learned: false,
      skip: false,
      attempt: 1,
      payload: { answerValue },
    });
  };

  /** @type {FnAsyncStringVoid} */
  const saveUsersAnswer = async (answerValue) => {
    await post(
      `exercises/saveAnswer/${exercise.exercise.id}/${currentDeck.id}`,
      { value: answerValue },
      null,
      {},
      true,
    );
  };

  /** @type {LearnProcessProviderContext} */
  const value = {
    word,
    videos,
    exercise,
    specifiedExerciseId: exerciseId,
    specifiedWordId: wordId,
    learnVideoResult,
    isExpertMode,
    selectedVideoIndex,
    areWordsExpanded,
    videoWordPatching,
    learnedVideos,
    rewards,
    learnWord,
    learnVideoWord,
    learnVideo,
    setExpertMode,
    setSelectedVideoIndex,
    setWordsExpanded,
    patchExercise,
    skipExercise,
    userIsWrong,
    requestOtherExercise,
    requestOtherWord,
    saveUsersAnswer,
  };
  return <learnContext.Provider value={value}>{children}</learnContext.Provider>;
};

export default LearnProcessProvider;
